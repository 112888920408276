'use client';

import Image from 'next/image';
import { Button } from '../button';
import { LottieSSRSafe } from '../lottie/LottieSSRSafe';
import { MonoHighlighter, Typography } from '../typography';
import Link from 'next/link';

export const ExpressiveEmptyState = ({
  imageUrl,
  lottieJson,
  lottieSize,
  highlightText,
  header,
  subtext,
  buttonText,
  buttonOnClick,
  buttonHref,
}: {
  highlightText: string;
  header: string;
  subtext: React.ReactNode;
  buttonText?: string;
  buttonOnClick?: () => void;
  buttonHref?: string;
  lottieSize?: { width: number; height: number };
} & (
  | { imageUrl?: string; lottieJson?: unknown }
  | { imageUrl?: undefined; lottieJson?: unknown }
)) => (
  <div
    className={
      'pb-6 h-full flex flex-col justify-center items-center max-w-md text-center w-full mx-auto'
    }
  >
    <div className={'mb-2'}>
      {imageUrl ? (
        <Image
          src={imageUrl}
          alt={''}
          className={'mx-auto mb-2'}
          width={240}
          height={240}
        />
      ) : (
        <LottieSSRSafe
          animationData={lottieJson}
          loop
          autoplay
          width={200}
          height={200}
          {...lottieSize}
        />
      )}
    </div>

    <div className={'w-fit mx-auto mb-3'}>
      <MonoHighlighter color={'lightGrey'}>{highlightText}</MonoHighlighter>
    </div>
    <Typography
      variant={'headline-lg'}
      className={
        'w-fit mx-auto mb-2 md:text-headline-large text-headline-medium'
      }
    >
      {header}
    </Typography>
    <Typography variant={'dense'} className={'w-fit mx-auto text-secondary'}>
      {subtext}
    </Typography>
    {buttonText && (
      <Button
        variant={'secondary'}
        size={'medium'}
        onClick={buttonOnClick}
        className={'mt-4'}
        asChild={buttonHref ? true : false}
      >
        {buttonHref ? <Link href={buttonHref}> {buttonText}</Link> : buttonText}
      </Button>
    )}
  </div>
);
