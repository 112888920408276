'use client';
import dynamic from 'next/dynamic';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });
import { useEffect, useState } from 'react';

/** SSR-safe wrapper around the <Lottie /> component which uses IntersectionObserver in the render, which breaks SSR */
export const LottieSSRSafe = ({
  width,
  height,
  ...restProps
}: React.ComponentProps<typeof Lottie> & {
  width: number;
  height: number;
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);

  // Avoid layout shift
  return (
    <div style={{ width, height }}>
      {show && <Lottie {...restProps} style={{ width, height }} />}
    </div>
  );
};
