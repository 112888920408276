import { cn } from '@howl/shared-utils/classnames/cn';
import { Typography } from './typography';
import { PropsWithChildren } from 'react';

// Component for Figma Monohighlighter pattern:
// https://www.figma.com/file/9v5qCXDkepP7HdPZ1goj5L/Howl.Next-%E2%86%92-Brand-Guidelines%E2%80%A8%26-Brand-System?type=design&node-id=204-5791&mode=design&t=eqID4OUgFWF5o8f6-0

type MonoHighlighterProps = PropsWithChildren<{
  size?: 'sm' | 'lg';
  color?: 'grey' | 'green' | 'lightGrey' | 'orange' | 'lime' | 'white';
  className?: string;
  stacked?: boolean;
}>;

const cnMonoHighlighterColors: Record<
  NonNullable<MonoHighlighterProps['color']>,
  string
> = {
  green: 'bg-primary-brand',
  grey: 'bg-secondary-medium-light-grey',
  lime: 'bg-secondary-lime',
  lightGrey: 'bg-secondary-medium-light-grey',
  orange: 'bg-secondary-orange',
  white: 'bg-white',
};

export const MonoHighlighter: React.FC<MonoHighlighterProps> = ({
  children,
  color = 'grey',
  size = 'sm',
  stacked = false,
  className,
}) => {
  return children ? (
    <Typography
      variant={size === 'lg' ? 'label-large' : 'label'}
      className="inline"
    >
      <span
        className={cn(
          'box-decoration-clone uppercase pb-[3px] pr-2',
          stacked ? 'pt-[0.5px] pl-[0.5px]' : 'pt-[1px] pl-[3px]',
          cnMonoHighlighterColors[color],
          className,
        )}
      >
        {children}
      </span>
    </Typography>
  ) : null;
};
