import React, { ElementType } from 'react';

type Variant =
  | 'display-lg'
  | 'display'
  | 'ball-pill'
  | 'ball-pill-lg'
  | 'dit-xl'
  | 'dit-lg'
  | 'dit-md'
  | 'dit-sm'
  | 'dit-xs'
  | 'headline-xl'
  | 'headline-lg'
  | 'headline-md'
  | 'headline-sm'
  | 'headline-xs'
  | 'body'
  | 'body-bold'
  | 'dense'
  | 'dense-bold'
  | 'caption'
  | 'caption-bold'
  | 'label-large'
  | 'label'
  | 'default';
interface TypographyProps {
  /* Typography size */
  variant: Variant;

  /* Mobile Only Typography size */
  mobileVariant?: Variant;

  /* HTML content */
  children: React.ReactNode;

  /* Tailwind classes to style element */
  className?: string;

  /* Set HTML tag */
  as?: ElementType;

  /* Cypress selector */
  dataCy?: string;
}

const tags: Record<Variant, ElementType> = {
  'display-lg': 'h1',
  display: 'h2',
  'ball-pill': 'h2',
  'ball-pill-lg': 'h1',
  'dit-xl': 'h1',
  'dit-lg': 'h2',
  'dit-md': 'h3',
  'dit-sm': 'h4',
  'dit-xs': 'h5',
  'headline-xl': 'h1',
  'headline-lg': 'h2',
  'headline-md': 'h3',
  'headline-sm': 'h4',
  'headline-xs': 'h5',
  body: 'p',
  'body-bold': 'p',
  dense: 'p',
  'dense-bold': 'p',
  caption: 'p',
  'caption-bold': 'p',
  'label-large': 'p',
  label: 'p',
  default: 'p',
};

const sizes: Record<Variant, string> = {
  'display-lg': 'text-display-large font-sans',
  display: 'text-display-default font-sans',
  'ball-pill': 'text-ball-pill font-ball-pill',
  'ball-pill-lg': 'text-ball-pill-large font-ball-pill',
  'dit-xl': 'text-dit-x-large font-dit',
  'dit-lg': 'text-dit-large font-dit',
  'dit-md': 'text-dit-medium font-dit',
  'dit-sm': 'text-dit-small font-dit',
  'dit-xs': 'text-dit-x-small font-dit',
  'headline-xl': 'text-headline-x-large font-sans',
  'headline-lg': 'text-headline-large font-sans',
  'headline-md': 'text-headline-medium font-sans',
  'headline-sm': 'text-headline-small font-sans',
  'headline-xs': 'text-headline-x-small font-sans',
  body: 'text-body font-sans font-medium',
  'body-bold': 'text-bold font-sans',
  dense: 'text-dense font-sans',
  'dense-bold': 'text-dense-bold font-sans',
  caption: 'text-caption font-sans',
  'caption-bold': 'text-caption-bold font-sans',
  'label-large': 'text-label-large font-mono',
  label: 'text-label-default font-mono',
  default: 'text-body font-mono',
};

const mobileSizes: Record<Variant, string> = {
  'display-lg': 'max-sm:text-display-large max-sm:font-sans',
  display: 'max-sm:text-display-default max-sm:font-sans',
  'ball-pill': 'max-sm:text-ball-pill max-sm:font-ball-pill',
  'ball-pill-lg': 'max-sm:text-ball-pill-large max-sm:font-ball-pill',
  'dit-xl': 'max-sm:text-dit-x-large font-dit',
  'dit-lg': 'max-sm:text-dit-large font-dit',
  'dit-md': 'max-sm:text-dit-medium font-dit',
  'dit-sm': 'max-sm:text-dit-small font-dit',
  'dit-xs': 'max-sm:text-dit-x-small font-dit',
  'headline-xl': 'max-sm:text-headline-x-large max-sm:font-sans',
  'headline-lg': 'max-sm:text-headline-large max-sm:font-sans',
  'headline-md': 'max-sm:text-headline-medium max-sm:font-sans',
  'headline-sm': 'max-sm:text-headline-small max-sm:font-sans',
  'headline-xs': 'max-sm:text-headline-x-small max-sm:font-sans',
  body: 'max-sm:text-body max-sm:font-sans',
  'body-bold': 'max-sm:text-bold max-sm:font-sans',
  dense: 'max-sm:text-dense max-sm:font-sans',
  'dense-bold': 'max-sm:text-dense-bold max-sm:font-sans',
  caption: 'max-sm:text-caption max-sm:font-sans',
  'caption-bold': 'max-sm:text-caption-bold max-sm:font-sans',
  'label-large': 'max-sm:text-label-large font-mono',
  label: 'max-sm:text-label-default font-mono',
  default: 'max-sm:text-body font-mono',
};

export const Typography = ({
  variant,
  mobileVariant,
  children,
  className = '',
  dataCy,
}: TypographyProps) => {
  let sizeClasses;
  const Tag = tags[variant];
  sizeClasses = sizes[variant];

  if (mobileVariant) {
    sizeClasses += ` ${mobileSizes[mobileVariant]}`;
  }

  return (
    <Tag data-cy={dataCy} className={`${sizeClasses} ${className}`}>
      {children}
    </Tag>
  );
};

Typography.displayName = 'Typography';
Typography.filePath = 'libs/shared/ui-kit/src/v3/typography/typography.tsx';
