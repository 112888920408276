'use client';

import { ExpressiveEmptyState } from '@howl/shared-ui-kit/v3/empty-state/expressive-empty-state';
import FlameLottie from '@howl/shared-ui-kit/v3/lottie/Flame.json';
import { Button } from '@howl/shared-ui-kit/v3/button';
import { useMediaQuery } from '@howl/shared-utils/hooks/use-media-query';

const ErrorPageVisual = ({
  error,
  reset = () => window.location.reload(),
}: {
  error: Error & { digest?: string };
  reset?: () => void;
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div className={'md:my-12'}>
      <ExpressiveEmptyState
        lottieJson={FlameLottie}
        lottieSize={!isMobile ? { width: 350, height: 350 } : undefined}
        highlightText={`ERROR CODE: ${error.digest}`}
        header={'Something went wrong.'}
        subtext={
          <div className={'flex flex-col gap-4 justify-center mx-auto'}>
            <span className={'md:text-headline-medium text-[20px] font-medium'}>
              If the problem persists, please contact us.
            </span>
            <div className={'flex gap-4 md:mt-6 justify-center'}>
              <a
                target={'_blank'}
                href={'https://help.howl.link/hc/en-us/requests/new'}
              >
                <Button
                  variant={'secondary'}
                  size={'medium'}
                  className={
                    'text-text-link h-64 md:px-10 md:py-8 font-normal md:text-[20px] text-[14px]'
                  }
                >
                  Report Issue
                </Button>
              </a>
              <Button
                variant={'primary'}
                size={'medium'}
                className={
                  'text-text-link h-64 md:px-10 md:py-8 font-normal md:text-[20px] text-[14px]'
                }
                onClick={() => reset()}
              >
                Try Again
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ErrorPageVisual;
