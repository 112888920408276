'use client';

import React from 'react';
import Image from 'next/image';

const ErrorPageLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className={'flex flex-col px-8 py-6 h-full justify-start'}>
      <Image
        src="/images/howl-nav-logo.png"
        alt="Howl Logo"
        height={24}
        width={160}
      />
      <div className={'flex flex-col my-auto h-auto'}>
        <div className={'flex flex-col align-middle justify-center'}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ErrorPageLayout;
